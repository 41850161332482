import * as React from "react";
import { IBlogInfo, IBlogPost } from "../../../../shared";
import { CodeSnippet } from "./CodeSnippet";

export type Location = [number, number];

export type ParserError = {
  tag: "error";
  reason: string;
  location: Location; // line and column
};

export type RenderedBlog = {
  tag: "blog"
  id: string;
  content: JSX.Element;
  info: IBlogInfo;
};

export const parseBlogPost = (blog: IBlogPost): RenderedBlog | ParserError => {
  let lexemes: string[] = blog.content.split("\n");
  let tokens: ParserResult = parse(lexemes, 1);
  if (!tokens) // Empty?
    return { tag: "error", reason: "Blog is empty", location: [0, 0] };

  if (tokens.tag === "error") 
    return tokens;

  let ret: RenderedBlog = {
    tag: "blog",
    id: blog.id,
    content: 
      <div>
        {...renderTokens(tokens, 0, blog)}
      </div>,
    info: blog.info
  };

  return ret;
}

const getFirstError = (r: ParserResult) => {
  if (!r) return r;
  if (r.tag === "error") return r;

  return getFirstError(r.next);
}

type Break = { tag: "break" };

type TextLink = {
  tag: "text";
  url: string;
  text: string;
}

type BoldText = {
  tag: "bold";
  text: string;
}

type Paragraph = {
  tag: "paragraph";
  text: (string | TextLink | BoldText)[];
};

type MarkdownHeading = {
  tag: "mdheading",
  size: number,
  text: string;
}

type CommandKeyword = "img" | "gallery" | "video" | "code";
type Command = {
  tag: "command";
  command: CommandKeyword;
  args: string[];
};

type ParserResult = Token | null | ParserError;

type Token = {
  tag: "token"
  value: Break | Paragraph | Command | MarkdownHeading;
  location: Location;
  next: Token | null;
};

const renderTokens = (token: Token | null, key: number, info: IBlogPost): JSX.Element[] =>  {
  if (token === null)
    return [<React.Fragment key={key} />];

  let elem: JSX.Element = <div key={key}></div>;

  switch (token.value.tag) {
    case "break":
      elem = <React.Fragment key={key} />;
      break;
    case "command":
      elem = renderCommand(token.value, key, info);
      break;
    case "mdheading":
      switch (token.value.size + 2) {
        case 1:
          elem = <h1 key={key}>{token.value.text}</h1>;
          break;
        case 2:
          elem = <h2 key={key}>{token.value.text}</h2>;
          break;
        case 3:
          elem = <h3 key={key}>{token.value.text}</h3>;
          break;
        case 4:
          elem = <h4 key={key}>{token.value.text}</h4>;
          break;
        case 5:
        defualt:
          elem = <h5 key={key}>{token.value.text}</h5>;
      }
      break;
    case "paragraph":
      elem = renderParagraph(token.value.text, key);
  }

  return [elem, ...renderTokens(token.next, key + 1, info)];
}

const renderCommand = (command: Command, key: number, info: IBlogPost): JSX.Element => {
  switch (command.command) {
    case "img":
    case "gallery":
      return renderGallery(info.id, command.args, key);
    case "video":
      return (
        <div className="blog-video-wrap" key={key}>
          <video controls className="blog-video">
            <source src={`/blogAsset/${info.id}/${command.args[0]}`} type="video/mp4"></source>
          </video> 
        </div>
      );
    case "code":
      return (
        <CodeSnippet key={key}
          snippetSource={`/blogAsset/${info.id}/${command.args[0]}`}
          language={command.args[1]} />
      );
  }

  return <div key={key}></div>
}

const renderGallery = (id: string, args: string[], key: number): JSX.Element => {
  // At this point we know args has even length
  let imgs: [string, string][] = [];
  for (let i = 0; i < args.length; i += 2) {
    imgs.push([args[i], args[i+1]]);
  }

  return (
    <div className="row blog-img-gallery" key={key}>
      {imgs.map(([img,caption], i) => 
        <figure key={i} className="figure col-md-4">
          <a href={`/blogAsset/${id}/${img}`} target="_blank">
            <img
              src={`/blogAsset/${id}/${img}`}
              className="figure-img blog-img rounded mx-auto d-block"
              alt="Uh oh spaghetti-o"
            ></img>
          </a>
          <figcaption className="blog-img-caption figure-caption">{caption}</figcaption>
        </figure>
      )}
    </div>
  );
}

const renderParagraph = (ps: (string | TextLink | BoldText)[], key): JSX.Element => {
  if (ps.length === 0)
    return <React.Fragment key={`${key}-empty`} />;

  let elems = ps.map((x,i) => {
    if (typeof x === 'string') 
      return x;
    else if (x.tag === "text")
      return <a key={i} href={x.url}>{x.text}</a>;
    else
      return <b key={i}>{x.text}</b>
  });

  return <p key={ps.toString()} >{...elems}</p>
}

const parse = (lines: string[], lineNumber: number): ParserResult => {
  // End of parsing
  if (lines.length === 0) {
    return null;
  }

  // This is just to shut the compiler up
  let line = lines.shift();
  if (line === null || line === undefined) {
    return null;
  }

  // Line break token
  if (line === "") {
    let next: ParserResult = parse(lines, lineNumber + 1);
    let resp: Token = {
      tag: "token",
      value: { tag: "break" },
      location: [lineNumber, 0],
      next: null,
    };
    if (next === null) 
      return resp;

    if (next.tag === "error")
      return next;

    return {...resp, next};
  }

  // A command token
  if (line.length >= 3 && line.slice(0,3) === "<$>") {
    let value = parseCommand(line, lineNumber);
    if (value.tag === "error")
      return value;

    let next: ParserResult = parse(lines, lineNumber + 1);
    let resp: Token = {
      tag: "token",
      value,
      location: [lineNumber, 0],
      next: null,
    };
    if (next === null) 
      return resp;

    if (next.tag === "error")
      return next;

    return {...resp, next};
  }

  // Markdown heading
  if (line[0] === '#') {
    let h = takeUntil(line, x => x !== '#');
    if (!h) {
      return {
        tag: "error",
        location: [lineNumber,0],
        reason: "Heading cannot be empty"
      };
    }

    let [heading, rest] = h;

    let next: ParserResult = parse(lines, lineNumber + 1);
    let resp: Token = {
      tag: "token",
      value: {
        tag: "mdheading",
        text: rest,
        size: heading.length
      },
      location: [lineNumber, 0],
      next: null,
    };
    if (next === null) 
      return resp;

    if (next.tag === "error")
      return next;

    return {...resp, next};
  }

  // Last but not least, a paragraph
  let paragraph = parseParagraph(line, lineNumber, 0);
  if (paragraph.tag === "error") {
    return paragraph;
  }

  let next = parse(lines,lineNumber + 1);

  let resp: Token = {
    tag: "token",
    value: paragraph,
    location: [lineNumber, 0],
    next: null,
  };

  if (!next) {
    return resp;
  }

  if (next.tag === "error")
    return next;

  return {...resp, next};
}

const takeUntil = (line: string, f: (arg: string) => boolean): [string, string] | null => {
  if (line.length === 0) 
    return null;

  if (f(line[0]))
    return ["", line];

  const res = takeUntil(line.slice(1), f);
  if (!res)
    return null;

  const [acc, rest] = res;

  return [line[0] + acc, rest];
}

const parseParagraph = (line: string, lineNumber: number, ind: number): Paragraph | ParserError => {
  // We need to find all the markdown links '[text](link)' and turn them into links
  if (line.length === 0) {
    return {
      tag: "paragraph",
      text: []
    }
  }

  // Parsing a *bold section*
  let bld = takeUntil(line, x => x === '*');
  if (bld) {
    let [text, rest] = bld;
    let bldStartPos = ind + text.length + 1;

    // if escaped
    if (text.slice(-1) === "\\") {
      text = text.slice(0,-1); // remove \

      let res = parseParagraph(rest, lineNumber, bldStartPos + 1);
      if (res.tag === "error") return res;

      return {
        tag: "paragraph",
        text: [text, ...res.text]
      }
    }

    // Remove *
    rest = rest.slice(1);

    // Now until the end
    let bld2 = takeUntil(rest, x => x === '*');
    if (!bld2) {
      return {
        tag: "error",
        reason: "Could not find ending *",
        location: [lineNumber, bldStartPos]
      };
    }

    let [text2, rest2] = bld2;

    // if escaped
    if (text2.slice(-1) === "\\") {
      return {
        tag: "error",
        reason: "Cannot escape * inside a bold tag",
        location: [lineNumber, bldStartPos]
      };
    }

    let bldEndPos = bldStartPos + 1 + text2.length;

    rest2 = rest2.slice(1);

    let res = parseParagraph(rest2, lineNumber, bldEndPos + 1);
    if (res.tag === "error") return res;

    return {
      tag: "paragraph",
      text: [text, { tag: "bold", text: text2 }, ...res.text]
    }
  }


  // If we got here, the only possibility is a link or text
  let res = takeUntil(line, x => x === '[');
  if (!res) {
    // No more tags left
    return {
      tag: "paragraph",
      text: [line]
    }
  }


  let [text, rest] = res;
  const linkStartPos = ind + text.length + 1;

  // Escaped [
  if (text.slice(-1) === "\\") {
    text = text.slice(0,-1); // remove \

    let res = parseParagraph(rest, lineNumber, linkStartPos + 1);
    if (res.tag === "error") return res;

    return {
      tag: "paragraph",
      text: [text, ...res.text]
    }
  }
  // Remove [
  rest = rest.slice(1);

  // Found a tag
  res = takeUntil(rest, x => x === ']');
  if (!res) {
    return {
      tag: "error",
      reason: "No closing link tag for tag '['",
      location: [lineNumber, linkStartPos]
    }
  }

  let [linkText, rest2] = res;
  const linkUrlStartPos = linkStartPos + linkText.length + 1;
  if (linkText === "") {
    return {
      tag: "error",
      reason: "Link text cannot be empty",
      location: [lineNumber, linkStartPos]
    }
  }

  // Remove ]
  rest2 = rest2.slice(1);

  if (rest2.length === 0 || rest2[0] !== '(') {
    return {
      tag: "error",
      reason: "No link url following link text",
      location: [lineNumber, linkUrlStartPos]
    }
  }

  // Remove (
  rest2 = rest2.slice(1);

  res = takeUntil(rest2, x => x === ')');
  if (!res) {
    return {
      tag: "error",
      reason: "No closing link tag for tag '('",
      location: [lineNumber, linkUrlStartPos]
    }
  }

  let [url, rest3] = res;
  const urlEndPos = linkUrlStartPos + url.length + 1;

  if (url === "") {
    return {
      tag: "error",
      reason: "URL text cannot be empty",
      location: [lineNumber, linkUrlStartPos]
    }
  }

  // Remove )
  rest3 = rest3.slice(1);
  
  let linkToken: TextLink = {
    tag: "text",
    url,
    text: linkText
  };

  let p = parseParagraph(rest3, lineNumber, urlEndPos);
  if (p.tag === "error")
    return p;

  p.text = [text, linkToken, ...p.text];

  return p;
}

const parseCommand = (line: string, lineNumber: number): Command | ParserError => {
  line = line.slice(3); // Remove <$>
  let ind = 3;

  // Check arguments
  if (line.length === 0) {
    return {
      tag: "error",
      reason: "Command tag (<$>) not given any arguments",
      location: [lineNumber, 3]
    };
  }

  ind++;

  // Check space
  if (line[0] !== " ") {
    return {
      tag: "error",
      reason: "Command tags (<$>) must be followed by a space then a command keyword",
      location: [lineNumber, 4]
    };
  }

  // remove space
  line = line.slice(1);

  if (line.length === 0) {
    return {
      tag: "error",
      reason: "Command tag (<$>) not given any arguments",
      location: [lineNumber, 4]
    };
  }

  let word = line.split(' ')[0];
  if (word !== "img" && word !== "gallery" && word !== "video" && word !== "code") {
    return {
      tag: "error",
      reason: `Command keyword ${word} not a valid keyword (must be one of 'img', 'gallery', 'video', 'code')`,
      location: [lineNumber, 4]
    };
  }

  ind += word.length;

  let args: (string | ParserError)[] = parseQuotedArguments(line.slice(word.length), ind, lineNumber);

  let error: ParserError | string | undefined = args.find(a => typeof a !== 'string');
  if (error && typeof error !== 'string') {
    return error;
  }
  const safeArgs = args.filter(a => typeof a === 'string') as string[];

  return validateCommand({ 
    tag: "command",
    command: word,
    args: safeArgs,
  }, lineNumber);
}

const validateCommand = (c: Command, lineNumber: number): Command | ParserError => {
  switch (c.command) {
    case "code":
      if (c.args.length !== 2) {
        return {
          tag:"error",
          reason: `code command expects 2 arguments, received ${c.args.length}`,
          location: [lineNumber, 0]
        };
      }
      break;
    case "gallery":
      if (c.args.length % 2 !== 0) {
        return {
          tag:"error",
          reason: `gallery command expects an even amount of arguments, received ${c.args.length}`,
          location: [lineNumber, 0]
        };
      }
      break;
    case "img":
      if (c.args.length !== 2) {
        return {
          tag:"error",
          reason: `img command expects 2 arguments, received ${c.args.length}`,
          location: [lineNumber, 0]
        };
      }
      break;
    case "video":
      if (c.args.length !== 1) {
        return {
          tag:"error",
          reason: `video command expects 1 argument, received ${c.args.length}`,
          location: [lineNumber, 0]
        };
      }
      break;
  }

  return c;
};

const parseQuotedArguments = (raw: string, ind: number, lineNumber: number): (string | ParserError)[] => {
  if (raw.length === 0) {
    return [];
  }

  if (/\s/.test(raw[0])) {
    raw = raw.slice(1);
    return parseQuotedArguments(raw, ind + 1, lineNumber);
  } else if (raw[0] !== '"') {
    return [{
      tag: "error",
      reason: `Expected quote around argument to command, got ${raw[0]}`,
      location: [lineNumber, ind]
    }];
  }

  raw = raw.slice(1);
  return takeQuotedArgument(raw, ind + 1, lineNumber, "");
}

const takeQuotedArgument = (raw: string, ind: number, lineNumber: number, acc: string): (string | ParserError)[] => {
  if (raw.length === 0) {
    return [{
      tag: "error",
      reason: `Quoted argument ended without closing quote`,
      location: [lineNumber, ind-1]
    }];
  }

  if (raw[0] === "\"") {
    return [acc, ...parseQuotedArguments(raw.slice(1), ind + 1, lineNumber)];
  }

  if (raw[0] === "\\") {
    if (raw.length < 2) {
      return [{
        tag: "error",
        reason: `Quoted argument ended without closing quote`,
        location: [lineNumber, ind]
      }];
    }

    if (raw[1] === "\"") {
      return [acc + raw.slice(0,2), ...parseQuotedArguments(raw.slice(2), ind + 2, lineNumber)];
    }
  }

  return takeQuotedArgument(raw.slice(1), ind + 1, lineNumber, acc + raw[0]);
}