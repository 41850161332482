import * as React from "react";
import { Header } from "./Header";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { FrontPage } from "./FrontPage/FrontPage";
import { Footer } from "./Footer";
import { Publications } from "./Publications/Publications";
import { FunZone } from "./Fun/FunZone";
import { Mandelbrot } from "./Fun/Mandelbrot";
import { CanvasExperiments } from "./Fun/CanvasExperiments";
import { TrainGame } from "./Fun/TrainGame";
import { Blog } from "./Blog/Blog";

export interface IAppRootProps {};

export class AppRoot extends React.Component<IAppRootProps, {}> {
  render() {
    return (
      <div className="container" id="main">
        <div>
          <BrowserRouter>
            <Header />
            <div className="content-wrap">
              <Switch>
                {/* Nav button pages */}
                <Route path="/" exact component={FrontPage}></Route>
                <Route path="/fun" component={FunZone}></Route>
                <Route path="/publications" component={Publications}></Route>

                {/* Games from 'fun' */}
                <Route path="/mandelbrot" component={Mandelbrot}></Route>
                <Route path="/traingame"  component={TrainGame}></Route>
                <Route path="/canvasExperiments" component={CanvasExperiments}></Route>

                {/* Blog pattern matching */}
                <Route path="/blog/:id?" component={Blog}></Route>
              </Switch>
            </div>
          </BrowserRouter>
        </div>
        <Footer />
      </div>
    );
  }
}