import * as React from "react";
import { Link } from "react-router-dom";

export class Header extends React.Component<{}, {}> {
  constructor(props) {
    super(props);
  }

  linkResume = () => {
    window.open("/resume");
  }

  render() {
    return (
      <div className="header-bar">
          <Link to="/" id="header-home-link">
            <h1 className="header-title-group">
              <img className="header-icon" src="/favicon.ico" />
              Welcome!
            </h1>
          </Link>

        <ul className="header-buttons-group">
          <Link to="/fun" className="header-button btn btn-primary">Fun</Link>
          <Link to="/publications" className="header-button btn btn-primary">Works</Link>
          <Link to="/blog" className="header-button btn btn-primary">Blog</Link>
        </ul>
      </div>
    );
  }
}