import * as React from "react";

interface ILoaderProps {
  width?: number;
}

export class Loader extends React.Component<ILoaderProps, {}> {

  render() {

    let styleProp = {
      width: `${this.props.width || "100px"}`,
      height: `${this.props.width || "100px"}`
    }

    return (
      <div className="coffee-loader"
        style={styleProp}
      >
        <img
          className="coffee-loader-img"
          src="/favicon.ico"
        ></img>
      </div>
    );
  }
}