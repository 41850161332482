import * as React from "react";
import { Prism } from "react-syntax-highlighter";
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism'

export interface ICodeSnippetProps {
  language: string;
  snippetSource: string;
};

interface ICodeSnippetState {
  code: string;
}

export class CodeSnippet extends React.Component<ICodeSnippetProps, ICodeSnippetState> {
  constructor(props) {
    super(props);

    this.state = {
      code: ""
    }

    this.loadSnippet();
  }

  loadSnippet = () => {
    fetch(this.props.snippetSource)
         .then(r => r.text())
         .then(src => this.setState({code: src}));
  };

  render() {
    return (
      <Prism
        className="code-snippet"
        language={this.props.language}
        style={coy}
        showLineNumbers={true}
        wrapLines={true}
        useInlineStyles={true}
      >
        {this.state.code}
      </Prism>
    );
  }

}