import * as React from "react";
import { BlogResponse, IBlogPost } from "../../../../shared";
import * as BP from "./BlogParser";
import { RouteComponentProps } from "react-router-dom";
import { Loader } from "../Loader";

interface IBlogState {
  posts: IBlogPost[];
  focusBlog?: string; // Id of current blog
};

interface IBlogProps extends RouteComponentProps {
}

export class Blog extends React.Component<IBlogProps, IBlogState> {

  constructor(props: IBlogProps) {
    super(props);

    this.state = {
      posts: [],
      focusBlog: props.match.params['id']
    };

    fetch("/blogPosts")
      .then(r => r.json())
      .then(this.loadBlogs)
      .catch(e => {
        console.error(e);
      });
  }

  componentDidUpdate = (oldProps: IBlogProps) => {
    // Update props if URL changed
    if (this.props.match.params['id'] !== oldProps.match.params['id'])
      this.setState({
        focusBlog: this.props.match.params['id']
      });
  };

  loadBlogs = async (r: BlogResponse | null) => {
    if (!r) {
      // Nothing we can do
      console.error("Got null response from server");
      return;
    }

    if (r.tag === "error") {
      // Something went wrong
      console.error(r.reason);
      return;
    }

    this.setState({
      posts: r.posts
    })
  }

  renderBlogCard = (blog: BP.RenderedBlog): JSX.Element => {
    return (
      <div className="card blog-card">
        <div className="card-body">
          <a href={`/blog/${blog.id}`}>
            <h2 className="blog-post-title">{blog.info.title}</h2>
          </a>
          <h4 className="blog-post-date">{blog.info.date}</h4>
        </div>
     </div>
     );
  }

  renderBlogPost = (blog: BP.RenderedBlog): JSX.Element => {
    return (
      <div>
        <a href={`/blog/${blog.id}`}>
          <h2 className="blog-post-title">{blog.info.title}</h2>
        </a>
        <h4 className="blog-post-date">{blog.info.date}</h4>
        {blog.content}
      </div>
    );
  }

  renderPosts = (): JSX.Element[] => {
    let parsed: (BP.RenderedBlog | null)[] = 
        this.state.posts
        .map(p => {
          let post = BP.parseBlogPost(p);
          if (post.tag === "error") {
            console.error(`Error parsing blog post ${p.id}: ${post.reason} at ${post.location}`);
            return null;
          } else {
            return post;
          }
        });

    let validBlogs: BP.RenderedBlog[] = parsed.filter(b => b !== null) as BP.RenderedBlog[];


    validBlogs.sort((x,y) => {
      let [xd, xm, xy] = x.info.date.split('/').map(Number);
      let [yd, ym, yy] = y.info.date.split('/').map(Number);
      return -(+(new Date(xy, xm, xd)) - +(new Date(yy, ym, yd)));
    });
    
    let blogsJsx: JSX.Element[] = [];
    if (this.state.focusBlog) {
      validBlogs = validBlogs.filter(x => x.id === this.state.focusBlog );
      if (validBlogs.length === 0) {
        return [<h1 key="fail" className="blog-404-message">
          404 not found - I don't know of any blog at the url '{`${window.location.href}`}'
        </h1>];
      }

      return [this.renderBlogPost(validBlogs[0])];
    }

    validBlogs.forEach((b,i) => {
      blogsJsx.push(this.renderBlogCard(b));
    });

    if (blogsJsx.length === 0 && this.state.posts.length !== 0) {
      // Invalid ID
      return [<h1 key="fail" className="blog-404-message">
        404 not found - I don't know of any blog at the url '{`${window.location.href}`}'
      </h1>];
    }

    return [
      <div className="blog-cards-wrap row">
      {...blogsJsx}
      </div>
    ];
  }

  render() {
    return (
      <div>
        {
          this.state.posts.length !== 0 ? (this.renderPosts())
                                        : <div className="coffee-loader-wrap">
                                            <Loader />
                                          </div>
        }
      </div>
    );
  }
}