import * as React from "react";

export class FrontPage extends React.Component<{}, {}> {

  render() {
    return (
      <div className="front-page-wrap">
        <h2>Hi there</h2>
        <p>Looks like you've stumbled on my website. Enjoy your time!</p>
      </div>
    );
  }
}