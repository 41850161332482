import * as React from "react";
import { ICoxcombOptions, drawCoxComb, DataPoint, Color } from "../../modules/canvas-experiments/src/coxcomb-chart/CoxComb";

export interface ICanvasExperimentsProps { }

type CanvasOptions = "coxcomb"

export interface ICanvasExperimentsState {
  currentCanvas: CanvasOptions;
  coxCombData: DataPoint[];
  coxCombOpts: ICoxcombOptions;
  canvasWidth: number;
}

const irrefutablePatterns = (x: never): never => {
  throw Error("Irrefutable pattern match failed");
}

type ColorPaletteName = "gray" | "peach" | "aqua"

const sampleData: DataPoint[] = [
  [1, 10],
  [3, 17],
  [9, 17],
  [16, 20],
  [21, 30],
  [2, 5],
  [7, 30],
  [11, 3],
  [14, 10],
  [19, 10],
]
sampleData.sort((d1, d2) => d2[0] - d1[0]);

const colorPaletteSelection: { [K in ColorPaletteName]: Color[] } = {
  "gray": [],
  "peach": ['#CE84AD', '#CE96A6', '#D1A7A0', '#D4CBB3', '#D2E0BF'],
  "aqua": ["#048390", "#007661", "#4cb681", "#81bc70", "#b3bd75"]
}

export class CanvasExperiments extends React.Component<ICanvasExperimentsProps, ICanvasExperimentsState> {
  private canvasId = "canvas-experiments";
  private mobileSize = 768;

  constructor(props) {
    super(props);
    this.state = {
      canvasWidth: 0,
      currentCanvas: "coxcomb",
      coxCombData: sampleData,
      coxCombOpts: {
        colorPalette: colorPaletteSelection["peach"]
      }
    };

    window.addEventListener('resize', this.setCanvasWidth);
  }


  private isMobile = () => window.innerWidth < this.mobileSize;

  setCanvasWidth = () => {
    const canvas = document.getElementById(this.canvasId) as HTMLCanvasElement | null;
    if (!canvas) {
      // If the viewport becomes big enough to fit the canvas again, rerender
      this.forceUpdate();
      return;
    };

    const e = document.getElementsByClassName("coxcomb-canvas-area-wrap");
    if (!e || e.length === 0) return;

    let width = (e.item(0) as HTMLElement).offsetWidth || 0;

    if (!this.isMobile())
      width = width * 0.6;

    canvas.width = width;
    canvas.height = width;

    this.setState({ canvasWidth: width })
  }

  componentDidMount = () => {
    drawCoxComb(this.state.coxCombData, this.canvasId, this.state.canvasWidth, this.state.coxCombOpts);
    this.setCanvasWidth();
  }

  componentDidUpdate = () => {
    const canvas = document.getElementById(this.canvasId) as HTMLCanvasElement | null;
    if (!canvas) return;

    const ctxt = canvas.getContext('2d');

    if (ctxt)
      ctxt.clearRect(0, 0, canvas.width, canvas.height)

    switch (this.state.currentCanvas) {
      case "coxcomb":
        drawCoxComb(this.state.coxCombData, this.canvasId, this.state.canvasWidth, this.state.coxCombOpts);
        return;
    }

    irrefutablePatterns(this.state.currentCanvas);
  }

  changeCanvas = (x: CanvasOptions) => this.setState({ currentCanvas: x });

  mkCoxCombColorButton = (className: string, colors: ColorPaletteName, text: String): JSX.Element => {
    return <button
      className={`btn ${className}`}
      onClick={() => this.setState({
        coxCombOpts: {
          ...this.state.coxCombOpts,
          colorPalette: colorPaletteSelection[colors]
        }
      })}
    >
      {text}
    </button>
  }


  private onDataRowEnter = (i: number) => (e) => {
    if (i < 0) return;
    this.setState({
      coxCombOpts: {
        ...this.state.coxCombOpts,
        highlight: i
      }
    });
  }

  private onDataRowLeave = (i: number) => (e) => {
    this.setState({
      coxCombOpts: {
        ...this.state.coxCombOpts,
        highlight: undefined
      }
    });
  }

  private renderCoxcombTable = (): JSX.Element[] => {
    const len = this.state.coxCombData.length;
    const data = [...this.state.coxCombData].reverse();
    return ([['X', 'Y'], ...[...this.state.coxCombData].reverse()]).map(([x, y], i) => {
      return (
        <tr key={`${x}-${y}-${i}`}
          onMouseEnter={this.onDataRowEnter(len - i)}
          onMouseLeave={this.onDataRowLeave(len - i)}
        >
          <td>{x}</td>
          <td>{y}</td>
        </tr>
      );
    });
  }

  private renderCoxcomb = () => {
    return (
      <div className="coxcomb-wrap">
        <p>Hover over/tap table cells to highlight the corresponding comb {this.isMobile() && "(Scroll down to find the chart)"}</p>
        <div className="coxcomb-split row">
          <div className="coxcomb-table-left col-4">
            <table id="coxcomb-data-table">
              <tbody>
                {this.renderCoxcombTable()}
              </tbody>
            </table>
          </div>
          <div className="coxcomb-canvas-area-wrap col-8">
            <canvas
              id={this.canvasId}>
            </canvas>
            <div className="canvas-coxcomb-buttons">
              {this.mkCoxCombColorButton("btn-peach", "peach", "Peach")}
              {this.mkCoxCombColorButton("btn-gray", "gray", "Gray")}
              {this.mkCoxCombColorButton("btn-aqua", "aqua", "Aqua")}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCanvas = (): JSX.Element => {
    switch (this.state.currentCanvas) {
      case "coxcomb":
        return this.renderCoxcomb();
    }

    irrefutablePatterns(this.state.currentCanvas);
  }


  render() {
    return (
      <div>
        <h4 className="canvas-title-text">Select a canvas experiment to view</h4>
        <div className="canvas-wrap">
          <div className="dropdown canvas-dropdown">
            <button
              className="btn btn-primary dropdown-toggle"
              id="canvas-dropdown-button"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Select a Canvas
            </button>
            <div className="dropdown-menu" aria-labelledby="canvas-dropdown-button">
              <a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); this.changeCanvas("coxcomb"); }} >
                Coxcomb
              </a>
            </div>
          </div>
          {this.renderCanvas()}
        </div>
      </div>
    );
  }

}