import * as React from "react";


export class Footer extends React.Component<{}, {}> {

    render() {
        return (
            <h1>
                <img
                    className="footer-image"
                    src="/assets/favicon.ico"
                />
            </h1>
        );
    }
}