// Generic server stuff

export interface IResponse {
  tag: string;
}

export interface IServerErrorResponse extends IResponse {
  tag: "error";
  reason: string;
}

export let validateResponse = (obj: any): obj is IResponse => {
  return obj && obj.tag;
}

// Mandelbrot

export interface IMandelbrotImageRequest {
  x: number;
  y: number;
  z: number;
}

export let validateMandelbrotImageRequest = (obj: any): obj is IMandelbrotImageRequest => {
  return obj
    && obj.x !== undefined && typeof obj.x === 'number'
    && obj.y !== undefined && typeof obj.y === 'number'
    && obj.z !== undefined && typeof obj.z === 'number'
}

export interface IMandelbrotImageResponse extends IResponse {
  tag: "mandelbrot";
  imageURL: string;
}

export type MandelbrotResponse = IMandelbrotImageResponse | IServerErrorResponse;

// Train game

export const validateTrainGameInput = (val: string): [boolean, string] => {
  if (!val || typeof val !== "string") {
    return [true, "input must be a space delimited string of integers"]
  }

  let tokens = val.split(" ")
                  .filter(v => !(/\s+/.test(v) || v === ""));
  
  return validateTrainGameNumbers(tokens)
}

export const validateTrainGameNumbers = (tokens: any[]): [boolean, string] => {
  let nums = tokens.map(s => Number(s));

  // Check all numbers
  let i = nums.findIndex(Number.isNaN);
  if (i !== -1) {
    return [true, `Element '${tokens[i].toString()}' is not an integer`];
  }

  // Check all whole integers
  i = nums.findIndex(x => !Number.isInteger(x));
  if (i !== -1) {
    return [true, `Element '${tokens[i].toString()}' is not an integer`];
  }

  if (nums.length < 2) {
    return [true, `input must contain at least 2 numbers`];
  }
  
  return [false, ''];
};

export type TrainGameRequest = string;
export interface ITrainGameResponse extends IResponse {
  tag: "train-game";
  solutions: string[];
  timedOut: boolean;
};

export interface ITrainGameRequest {
  numbers: number[];
};

export const validateTrainGameRequest = (r: any): r is ITrainGameRequest => {
  return r && typeof r === "object" 
           && r.numbers 
           && typeof r.numbers === "object";
}

export type TrainGameResponse = ITrainGameResponse | IServerErrorResponse;

/* Blog */

export interface IBlogInfo {
  title: string;
  date: string;
};

export const validateBlogInfo = (i: any): i is IBlogInfo => {
  return i && i.title
           && typeof i.title === "string"
           && i.date
           && typeof i.date === "string"
           && Date.parse(i.date) !== NaN;
}

export interface IBlogPost {
  info: IBlogInfo;
  content: string;
  id: string;
};


export interface IBlogResponse {
  tag: "blogs"
  // blog post details and post content
  posts: IBlogPost[];
};

export type BlogResponse = IBlogResponse | IServerErrorResponse;