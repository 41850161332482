import * as React from "react"; 
import {validateTrainGameInput, ITrainGameRequest, TrainGameResponse} from "../../../../shared";
import { Loader } from "../Loader";

interface ITrainGameState {
  inputError: boolean;
  errorMsg: string;
  loading: boolean;
  solutions: string[] | null;
  timedOut: boolean;
}

export class TrainGame extends React.Component<{}, ITrainGameState> {

  constructor(props){
    super(props);

    this.state = {
      inputError: false,
      loading: false,
      solutions: null,
      errorMsg: "",
      timedOut: false,
    };
  }

  validateInput = (e: React.FormEvent<HTMLInputElement>) => {
    if (!e) {
      this.setState({ inputError: true, errorMsg: "Unknown error" });
    }

    let val = e.currentTarget.value;
    let [err, reason] = validateTrainGameInput(val);

    this.setState({ errorMsg: reason, inputError: err });
  }
  
  fetchSolutions = async (e) => {
    if (this.state.inputError)
      return;

    this.setState({ loading: true, solutions: null });

    let req: ITrainGameRequest = {
      numbers: (document.getElementById("train-game-input-form") as HTMLInputElement)
                .value.split(" ")
                .filter(v => !(/\s+/.test(v) || v === ""))
                .map(Number),
    };

    let res: TrainGameResponse = await fetch("/trainGameSolutions", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(req),
    }).then(r => r.json())
      .catch(e => {
        console.error(e.toString());
        return null;
      });
    
    if (!res) {
      // Something went wrong
      console.error("Response body invalid");
      return;
    }

    if (res.tag === "error") {
      this.setState({ inputError: true, errorMsg: res.reason });
    } else {
      this.setState({
        solutions: res.solutions,
        timedOut: res.timedOut,
        loading: false,
      });
    }
  };

  renderSolutions = (): JSX.Element => {
    if (this.state.solutions === null)
      return <React.Fragment />;

    if (this.state.solutions === []) {
      return <h5>Sorry, no solutions!</h5>;
    }

    return <div>
      {
        this.state.solutions.map(s => 
          <h5 key={s}>10 = {s}</h5>
        )
      }
    </div>;
  }

  timeoutMsg = (): JSX.Element => {
    if (!this.state.solutions)
      return <React.Fragment />;

    if (this.state.solutions.length === 0 && this.state.timedOut)
      return <h5>Program timed out before any solutions could be calculated :(</h5>

    if (this.state.timedOut)
      return <h5>... And there may be more, but the program timed out before they could be calculated!</h5>;

    if (this.state.solutions.length === 0)
      return <h5>Looks like there's no solutions!</h5>

    return <h5>And that's all the possible solutions!</h5>
  };

  render() {

    return (
      <div>
        <h2>Train Game</h2>
        <p>
          The train game is a game where you use a list of numbers (for example, 1, 2, 3 and 4) and the operators +, -, * and / to make the number 10.
        </p>
        <p>
          Read more <a href="https://github.com/emmet-m/train-game">here</a>!
        </p>
        <div className="train-game-column">
          <h4>Enter (space delimited) numbers:</h4>
          <input 
            defaultValue="1 2 3 4"
            className={"form-control" + (this.state.inputError ? " is-invalid" : "")}
            id="train-game-input-form"
            onChange={this.validateInput}
          ></input>
          {this.state.inputError && <p className="game-error-text">{this.state.errorMsg}</p>}
          <button className="btn btn-primary" onClick={this.fetchSolutions}>Generate solutions!</button>

          <div className="train-game-solutions">
            {this.state.loading && <Loader />}
            { this.state.solutions !== null &&
              <div>
                <h5>A total of {this.state.solutions.length} solution{this.state.solutions.length === 1 ? "" : "s"}</h5>
                {this.renderSolutions()}
                { this.timeoutMsg() }
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}