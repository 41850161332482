import * as React from "react";
import { Link } from "react-router-dom";

export interface IGameProps {
  imageRef: string;
  name: string;
  text: string;
  buttonText: string;
  link: string;
}

export class Game extends React.Component<IGameProps, {}> {

  render() {
    return (
      <div className="card game-card">
        <img className="card-img-top" src={this.props.imageRef} alt="Whoops"></img>
        <div className="card-body">
          <h3 className="card-title">{this.props.name}</h3>
          <p className="card-text">{this.props.text}</p>
          <Link to={this.props.link} className="btn btn-primary">{this.props.buttonText}</Link>
        </div>
      </div>
    );
  }
};