import * as React from "react";
import { Game } from "./Game";

export class FunZone extends React.Component<{}, {}> {

  render() {
    return (
      <div className="fun-zone">
        <Game
          imageRef="assets/mandelbrot.jpg"
          name="Mandelbrot"
          text="Generate the mandelbrot set and zoom around the recursive fractal explorer!"
          buttonText="Fractals!"
          link="/mandelbrot"
        />
        <Game
          imageRef="assets/traingame.jpg"
          name="Train Game"
          text="Get easy answers to your favourite commuting arithmetic puzzle!"
          buttonText="Answers!"
          link="/traingame"
        />
        <Game
          imageRef="assets/canvas.jpg"
          name="Canvas Experiments"
          text="Fun charts and animations with HTML5 Canvas!"
          buttonText="Canvas!"
          link="/canvasExperiments"
        />
      </div>
    );
  }
};
