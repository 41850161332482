import * as React from "react";

export interface IPublicationProps {
  title: string;
  authors: string[];
  description: string;
  link: string;
}

export class Publication extends React.Component<IPublicationProps, {}> {
  authors = () => {
    return <i>
      {this.props.authors.join(", ")}
    </i>
  }

  render() {
    return (
      <div>
        <a href={this.props.link}>
          <h4>{this.props.title}</h4>
        </a>
        {this.authors()}
        <p>{this.props.description}</p>
      </div>
    );
  }
}