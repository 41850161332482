import * as React from "react";
import { IMandelbrotImageRequest, IMandelbrotImageResponse, MandelbrotResponse, IServerErrorResponse } from "../../../../shared";
import { Loader } from "../Loader";

interface IMandelbrotState {
  loading: boolean;
  error: string | null;
}

export class Mandelbrot extends React.Component<{}, IMandelbrotState> {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null
    };

    // fetch image for first time
    fetch("generateMandelbrotImage", 
      {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({x: 0, y: 0, z: 7}),
      })
      .then(r => r.json())
      .then((resp : IMandelbrotImageResponse | IServerErrorResponse) => {
        if (resp.tag !== "mandelbrot")
          throw resp.reason;

        this.loadImage(resp.imageURL);
      })
      .catch(e => this.setError(e.toString()));
  }

  setError = e => this.setState({ error: e })
  clearError = () => this.setState ({ error: null })

  reloadImage = async (e) => {
    this.clearError();
    this.setState({ loading: true });

    // Construct out request
    let obj: object | null = {};
    ["x","y","z"].map(c => {
      let node = document.getElementById(`mandelbrot-${c}-coord-input`) as HTMLInputElement;
      if (!node) {
        obj = null;
      }
      obj && (obj[c] = Number(node.value));
    });

    if (!obj) {
      console.error("Failed to construct request object");
      return;
    }

    // Call server
    let reqObj: IMandelbrotImageRequest = obj as IMandelbrotImageRequest;
    let resp: MandelbrotResponse = await fetch("generateMandelbrotImage", 
      {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(reqObj),
      }).then(r => r.json())
      .catch(e => e.toString());

    // Set image url if exists
    if (!resp) {
      this.setError("Failed to get response from server");
    } else if (resp.tag === "error") {
      this.setError(`Server failed: ${resp.reason}`);
    } else {
      this.loadImage(resp.imageURL);
    }

    this.setState({ loading: false });
  }

  getImageWidth = (): number | undefined => {
    let node: HTMLImageElement = document.getElementById("mandelbrot-image") as HTMLImageElement;
    if (!node) {
      return undefined;
    }

    return node.width;
  }

  // Preloads an image so rendering doesn't flash
  loadImage = (src) => {
    var img = new Image(),
        x = document.getElementById("mandelbrot-image") as HTMLImageElement;

    img.onload = function() {
        x.src = img.src;
    };

    img.src = src;
  }

  render() {
    return (
      <div>
        <h2>Mandelbrot Fractal Generator</h2>
        <p>Generate some fractals parametarised by x, y and z(oom) coordinates.</p>

        <div className="mandelbrot-column">
          {/* Image wrapper and frame */}
          <div className="mandelbrot-frame-wrap">
            <div className="mandelbrot-frame mandelbrot-img-thumbnail">
              {
                this.state.loading &&
                  <Loader
                    width={this.getImageWidth()}
                  /> 
              }
              <img 
                id="mandelbrot-image" 
                className={this.state.loading ? "mandelbrot-image-loading" : ""}
                // Will be updated by loadImage
              />
            </div>
          </div>

          {this.state.error !== null && 
            <p className="game-error-text">
              {this.state.error}
            </p>
          }
          
          <button className="btn btn-primary mandelbrot-generate-button" onClick={this.reloadImage}>Generate!</button>

          {/* Form for entering coordinates */}
          <div className="form-group mandelbrot-coordinate-input">
            <div className="mandelbrot-field-wrap">
              <label htmlFor="mandelbrot-x-coord-input">x</label>
              <input 
                className="form-control mandelbrot-coord-field" 
                type="number" 
                id="mandelbrot-x-coord-input" 
                defaultValue="0" 
                step="0.1"
              />
            </div>

            <div className="mandelbrot-field-wrap">
              <label htmlFor="mandelbrot-y-coord-input">y</label>
              <input 
                className="form-control mandelbrot-coord-field" 
                type="number" 
                id="mandelbrot-y-coord-input" 
                defaultValue="0" 
                step="0.1"
              />
            </div>

            <div className="mandelbrot-field-wrap">
              <label htmlFor="mandelbrot-z-coord-input">z</label>
              <input 
                className="form-control mandelbrot-coord-field" 
                type="number" 
                id="mandelbrot-z-coord-input" 
                defaultValue="7" 
                step="1"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
};