import * as React from "react";
import { Publication, IPublicationProps } from "./Publication";

export class Publications extends React.Component<{}, {}> {

  private pubs: IPublicationProps[] = [
    {
      title: "Undergraduate Thesis",
      authors: ["Emmet Murray"],
      description: "My undergraduate thesis, where I added recursive types and basic termination checking to the programming language Cogent.",
      link: "https://github.com/emmet-m/thesis",
    }
  ];

  renderPublications = () => {

    // This is all to avoid the 'keys' problem
    return  this.pubs
                .reduce((acc, elem, ind) => {
                  return {
                    a: [
                      ...acc.a, 
                      <Publication {...elem} key={acc.b} />, 
                      <hr key={acc.b+1}/>
                    ], 
                    b: acc.b+2
                  }
                }, {a:[],b:0}).a
                .slice(0,-1);
  };

  render() {
    return (
      <div id="publications">
        <h2>Here's a list of all my works and publications:</h2>
        {this.renderPublications()}
      </div>
    );
  }
}